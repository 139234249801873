.App{
  display: flex;
  flex-direction: row;
}

.left{
  
  display: flex;
  flex-direction: column;
}
.left a{
  font-size: 15px;
  text-align: center;
}

.right{
  padding-left: 80vh;
  width: 50%;
  
}

.idsearch{
  margin-top: 15px;
}

@media print {
  .right {
    display: none !important;
  }
  /* body{
    width: 3cm;
    height: 6cm;
  } */
}